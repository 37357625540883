
import moment from "moment";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import IButton from "@/ui-components/IButton/IButton.vue";

@Component({
  components: {
    IButton
  },
  name: "YearsDateSelector",
})
export default class YearsDateSelector extends Vue {
  @Prop({ type: String }) start!: string;
  @Prop({ type: String }) end!: string;
  @Prop({ type: Number }) incrementStep!: number;

  @Emit('change')
  increment() {
    const newStartDate = moment(this.start).add(this.incrementStep, 'years').toISOString();
    const newEndDate = moment(this.end).add(this.incrementStep, 'years').toISOString();
    return {
      start: newStartDate,
      end: newEndDate,
    }
  }

  @Emit('change')
  decrement() {
    const newStartDate = moment(this.start).subtract(this.incrementStep, 'years').toISOString();
    const newEndDate = moment(this.end).subtract(this.incrementStep, 'years').toISOString();
    return {
      start: newStartDate,
      end: newEndDate,
    }
  }
}
