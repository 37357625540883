
import { Component, Vue } from 'vue-property-decorator';
import PageTitle from '@/ui-components/PageTitle/PageTitle.vue';
import QuarterEarnings from './components/QuarterEarnings/QuarterEarnings.vue';
import QuarterPlan from './components/QuarterPlan/QuarterPlan.vue';
import RunningCampaigns from './components/RunningCampaigns/RunningCampaigns.vue';
import TransactionsHistory from './components/TransactionsHistory/TransactionsHistory.vue';
import EarningsHistory from './components/EarningsHistory/EarningsHistory.vue';
import { Action, Getter } from 'vuex-class';
import namespaces from '@/store/namespaces';
import { PlannedCampaignCategory } from '@/types/financial';
import { Campaign } from '@/types/campaigns';
import {
  LoadRunningCampaigns,
  SetEarningsHistoryDateRange,
  SetEarningsHistorySelectedLocation,
  SetQuarterEarningsDateRange,
} from '@/store/modules/financial-module/types';
import { LoadLocations } from '@/store/modules/common-module/types';
import { LocationData } from '@/types/locations';

@Component({
  name: 'FinancialPage',
  components: {
    PageTitle,
    QuarterEarnings,
    QuarterPlan,
    RunningCampaigns,
    TransactionsHistory,
    EarningsHistory,
  },
})
export default class FinancialPage extends Vue {
  @Action('loadRunningCampaigns', { namespace: namespaces.FinancialModule })
  public loadRunningCampaigns!: LoadRunningCampaigns;

  @Getter('thisQuarterStartDate', { namespace: namespaces.FinancialModule })
  public thisQuarterStartDate!: string;

  @Getter('transactionsHistory', { namespace: namespaces.FinancialModule })
  public transactionsHistory!: TransactionsHistory[];

  @Getter('thisQuarterEndDate', { namespace: namespaces.FinancialModule })
  public thisQuarterEndDate!: string;

  @Getter('selectedQuarterEarningStartDate', {
    namespace: namespaces.FinancialModule,
  })
  public selectedQuarterEarningStartDate!: string;

  @Getter('selectedQuarterEarningEndDate', {
    namespace: namespaces.FinancialModule,
  })
  public selectedQuarterEarningEndDate!: string;

  @Getter('quarterEarningsChartData', { namespace: namespaces.FinancialModule })
  public quarterEarningsChartData!: any;

  @Getter('statisticsStatus', { namespace: namespaces.FinancialModule })
  public statisticsStatus!: number;

  @Getter('currentEarnings', { namespace: namespaces.FinancialModule })
  public currentEarnings!: number;

  @Getter('expectedEarnings', { namespace: namespaces.FinancialModule })
  public expectedEarnings!: number;

  @Getter('plannedCampaignsCategories', {
    namespace: namespaces.FinancialModule,
  })
  public plannedCampaignsCategories!: PlannedCampaignCategory[];

  @Getter('runningCampaigns', { namespace: namespaces.FinancialModule })
  public runningCampaigns!: Campaign[];

  @Getter('totalPlannedCampaigns', { namespace: namespaces.FinancialModule })
  public totalPlannedCampaigns!: number;

  @Getter('earningsHistoryLocationsCount', {
    namespace: namespaces.FinancialModule,
  })
  public earningsHistoryLocationsCount!: number;

  @Getter('earningsHistoryCampaignsCount', {
    namespace: namespaces.FinancialModule,
  })
  public earningsHistoryCampaignsCount!: number;

  @Getter('earningsHistorySelectedLocation', {
    namespace: namespaces.FinancialModule,
  })
  public earningsHistorySelectedLocation!: string;

  @Getter('earningsHistoryStartDate', { namespace: namespaces.FinancialModule })
  public earningsHistoryStartDate!: string;

  @Getter('earningsHistoryEndDate', { namespace: namespaces.FinancialModule })
  public earningsHistoryEndDate!: string;

  @Getter('totalEarningsHistory', { namespace: namespaces.FinancialModule })
  public totalEarningsHistory!: number;

  @Getter('locations', { namespace: namespaces.CommonModule })
  public locations!: LocationData[];

  @Action('setQuarterEarningsDateRange', {
    namespace: namespaces.FinancialModule,
  })
  public setQuarterEarningsDateRange!: SetQuarterEarningsDateRange;

  @Action('setEarningsHistoryDateRange', {
    namespace: namespaces.FinancialModule,
  })
  public setEarningsHistoryDateRange!: SetEarningsHistoryDateRange;

  @Action('loadLocations', { namespace: namespaces.CommonModule })
  public loadLocations!: LoadLocations;

  @Action('setEarningsHistorySelectedLocation', {
    namespace: namespaces.FinancialModule,
  })
  public setEarningsHistorySelectedLocation!: SetEarningsHistorySelectedLocation;

  public async mounted() {
    await this.loadRunningCampaigns();
    await this.loadLocations();
  }
}
