
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import BigCard from '@/ui-components/BigCard/BigCard.vue';
import DatePickerInput from '@/ui-components/DatePickerInput/DatePickerInput.vue';
import InfoCircle from '@/ui-components/InfoCircle/InfoCircle.vue';
import MultiSelect from '@/ui-components/MultiSelect/MultiSelect.vue';
import { DateRange, Nullable } from '@/types/misc';
import { LocationData } from '@/types/locations';
import moment from 'moment';

@Component({
  name: 'EarningsHistory',
  components: {
    InfoCircle,
    BigCard,
    DatePickerInput,
    MultiSelect,
  },
})
export default class EarningsHistory extends Vue {
  @Prop({ type: String }) public endDate!: string;
  @Prop({ type: String }) public startDate!: string;
  @Prop({ type: Number }) public total!: number;
  @Prop({ type: Number }) public locationsCount!: number;
  @Prop({ type: Number }) public campaignsCount!: number;
  @Prop({ type: String, default: '' }) public selectedLocation!: string;
  @Prop({ type: Array, default: () => [] }) public locations!: LocationData[];

  public calendarIcon = '/assets/icons/calendar.svg';

  public get allLocations(): LocationData[] {
    return [
      {
        NAME: this.$t('all_locations') as string,
        ID: '',
      } as LocationData,
      ...this.locations,
    ];
  }

  public get selectedLocationId(): string {
    return this.selectedLocation;
  }

  public set selectedLocationId(val: string) {
    this.locationChanged(val);
  }

  public get dateRange(): Nullable<string>[] {
    const startDate = this.startDate ? moment(this.startDate).format('YYYY-MM-DD') : null;
    const endDate = this.endDate ? moment(this.endDate).format('YYYY-MM-DD') : null;
    return [startDate, endDate];
  }

  public get formattedDateRange(): string {
    return !this.startDate && !this.endDate
      ? ''
      : this.dateRange
        .map((date: Nullable<string>) => date ? moment(date).format('YYYY') : '')
        .join('~');
  }

  public set dateRange(val: Nullable<string>[]) {
    this.dateChanged(val);
  }

  @Emit('locationChanged')
  locationChanged(val: string) {
    return val;
  }

  @Emit('dateRangeChanged')
  dateChanged(val: Nullable<string>[]) {
    const [date] = val;

    if (this.startDate && !this.endDate) {
      return {
        start: this.startDate,
        end: date
      };
    }

    return {
      start: date,
      end: null
    };
  }
}
