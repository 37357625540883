
import { TransactionHistory } from '@/types/financial';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Transaction from './Transaction.vue';

@Component({
  name: 'Transactions',
  components: {
    Transaction,
  },
})
export default class Transactions extends Vue {
  @Prop({ type: Array, default: () => [] })
  public transactions!: TransactionHistory[];

  public goToTransactionsHistory() {
    // this.$router.push();
  }
}
