
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FileData } from '@/types/financial';
import { downloadBlob } from '@/utils/files';

@Component({
  name: 'FileDownload',
})
export default class FileDownload extends Vue {
  @Prop({ type: Object }) file!: FileData;

  public async download() {
    try {
      const { name, path } = this.file;
      const { blob: blobContainer } = await fetch(path, { mode: 'no-cors'});
      const blob = await blobContainer();
      return downloadBlob(blob, name);
    } catch (error) {
      // TODO: Add error toastr 
    }
  }
}
