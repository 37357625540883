import { render, staticRenderFns } from "./EarningSection.vue?vue&type=template&id=9fe7c0ec&scoped=true"
import script from "./EarningSection.vue?vue&type=script&lang=ts"
export * from "./EarningSection.vue?vue&type=script&lang=ts"
import style0 from "./EarningSection.vue?vue&type=style&index=0&id=9fe7c0ec&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9fe7c0ec",
  null
  
)

export default component.exports