
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
  name: 'StatisticsStatus',
})
export default class StatisticsStatus extends Vue {
  @Prop({ type: Number, default: 0 }) status!: number;
}
