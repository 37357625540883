
import { Component, Prop, Vue } from 'vue-property-decorator';
import TransactionsHistory from '../TransactionsHistory.vue';
import FileDownload from '@/ui-components/FileDownload/FileDownload.vue';

@Component({
  name: 'Transaction',
  components: {
    FileDownload,
  },
})
export default class Transaction extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  public transaction!: TransactionsHistory;
  @Prop({ type: Boolean, default: true })
  public extendLine!: boolean;
}
