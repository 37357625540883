
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'QuarterEarnings',
  components: {},
})
export default class QuarterEarnings extends Vue {
  @Prop({ type: Number }) public amount!: number;
  @Prop({ type: String }) public hint!: string;
  @Prop({ type: String }) public title!: string;
  @Prop({ type: String }) public icon!: string;
}
