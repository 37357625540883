
import { Component, Prop, Vue } from 'vue-property-decorator';
import BigCard from '@/ui-components/BigCard/BigCard.vue';
import CompactCampaignsTable from '@/shared-components/CompactCampaignsTable.vue';
import { CompactCampaign } from '@/types/campaigns';
import { storeOwnerRouteNames } from '@/route-names/store-owner';

@Component({
  name: 'RunningCampaigns',
  components: {
    CompactCampaignsTable,
    BigCard,
  },
})
export default class RunningCampaigns extends Vue {
  @Prop({ type: Array, default: () => [] })
  public runningCampaigns!: CompactCampaign[];

  public goToRunningCampaigns() {
    this.$router.push(storeOwnerRouteNames.INCOMING_CAMPAIGNS);
  }
}
