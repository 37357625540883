
import { Component, Prop, Vue } from 'vue-property-decorator';
import BigCard from '@/ui-components/BigCard/BigCard.vue';
import InfoCircle from '@/ui-components/InfoCircle/InfoCircle.vue';
import { PlannedCampaignCategory } from '@/types/financial';

@Component({
  name: 'QuarterPlan',
  components: {
    BigCard,
    InfoCircle,
  },
})
export default class QuarterPlan extends Vue {
  @Prop({ type: Number, default: 0 }) public total!: number;
  @Prop({ type: String }) public thisQuarterStartDate!: string;
  @Prop({ type: String }) public thisQuarterEndDate!: string;
  @Prop({ type: Array, default: () => [] })
  public plannedCampaignsCategories!: PlannedCampaignCategory[];
}
