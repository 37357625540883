
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import BigCard from '@/ui-components/BigCard/BigCard.vue';
import InfoCircle from '@/ui-components/InfoCircle/InfoCircle.vue';
import StatisticsStatus from '@/ui-components/StatisticsStatus/StatisticsStatus.vue';
import YearsDateSelector from '@/ui-components/YearsDateSelector/YearsDateSelector.vue';
import EarningSection from './components/EarningSection.vue';
import QuartersChart from './components/QuartersChart.vue';
import { DateRange } from '@/types/misc';

@Component({
  name: 'QuarterEarnings',
  components: {
    BigCard,
    InfoCircle,
    StatisticsStatus,
    EarningSection,
    QuartersChart,
    YearsDateSelector,
  },
})
export default class QuarterEarnings extends Vue {
  @Prop({ type: String }) public thisQuarterStartDate!: string;
  @Prop({ type: String }) public thisQuarterEndDate!: string;
  @Prop({ type: String }) public selectedQuarterEarningStartDate!: string;
  @Prop({ type: String }) public selectedQuarterEarningEndDate!: string;
  @Prop({ type: Number, default: 0 }) public statisticsStatus!: number;
  @Prop({ type: Number, default: 0 }) public currentEarnings!: number;
  @Prop({ type: Number, default: 0 }) public expectedEarnings!: number;
  @Prop({ type: Object }) public chartData!: any;

  @Emit('dateRangeChanged')
  dateChanged(val: DateRange) {
    return val;
  }
}
