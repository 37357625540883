
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'QuartersChart',
  components: {},
})
export default class QuartersChart extends Vue {
  @Prop({ type: Object }) public data!: any;
}
