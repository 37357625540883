
import { Component, Prop, Vue } from 'vue-property-decorator';
import BigCard from '@/ui-components/BigCard/BigCard.vue';
import Transactions from './components/Transactions.vue';

@Component({
  name: 'TransactionsHistory',
  components: {
    BigCard,
    Transactions,
  },
})
export default class TransactionsHistory extends Vue {
  @Prop({ type: Array, default: () => [] })
  public transactions!: TransactionsHistory[];

  public goToTransactionsHistory() {
    // this.$router.push();
  }
}
